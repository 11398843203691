<template>
  <div
    class="d-flex flex-column flex-shrink-0 p-3 sidebar"
    :style="getSideMenuSize"
    ref="sidebar"
    @mouseleave="closeDropdown"
  >
    <!-- Navigation -->
    <ul class="nav nav-pills flex-column mb-auto">
      <li
        v-for="(sideBarItem, i) in menu"
        :key="sideBarItem._id"
        class="nav-item"
      >
        <div
          v-if="sideBarItem.children && sideBarItem.children.length"
          class="dropend position-relative"
        >
          <div
            class="nav-link d-flex justify-content-between align-items-center"
            @click="openDropdown(i, $event)"
          >
            <div class="d-flex align-items-center">
              <icons
                v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                :iconName="sideBarItem.icon"
                class="me-3 sidebar-icon"
              />
              <span v-if="isLabelHidden">
                {{
                  sideBarItem.enableFullTitle
                    ? sideBarItem.title
                    : $options.filters.truncate(sideBarItem.title, 15)
                }}
              </span>
            </div>
            <span class="dropdown-arrow">&#9654;</span>
          </div>

          <ul
            v-show="activeDropdown === i"
            class="dropdown-menu show"
            :style="{
              top: '5' + '%',
              left: '110' + '%',
            }"
            ref="dropdown"
          >
            <li
              v-for="sideBarSubItem in sideBarItem.children"
              :key="sideBarSubItem._id"
              @click="routeClick(sideBarSubItem, true)"
            >
              <div class="dropdown-item">
                <icons
                  v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                  :iconName="sideBarSubItem.icon"
                  class="me-3 sidebar-icon"
                />
                <span v-if="isLabelHidden">
                  {{ sideBarSubItem.title }}
                </span>
              </div>
            </li>
          </ul>
        </div>

        <template v-else>
          <div
            class="nav-link d-flex align-items-center"
            @click="routeClick(sideBarItem, true)"
          >
            <icons
              v-if="getCompanyDetails && getCompanyDetails.menuIcon"
              :iconName="sideBarItem.icon"
              class="me-3 sidebar-icon"
            />
            <span v-if="isLabelHidden">
              {{ sideBarItem.title }}
            </span>
          </div>
        </template>
      </li>

      <!-- Config Menu Integration -->
      <li
        v-for="(configBarItem, i) in configMenu"
        :key="i + configBarItem._id"
        class="nav-item"
        @click="routeClick(configBarItem, false)"
        :class="{
          'dynamic-height': configBarItem.enableFullTitle,
          'fixed-height': !configBarItem.enableFullTitle,
        }"
      >
        <div class="nav-link d-flex align-items-center">
          <icons
            v-if="getCompanyDetails && getCompanyDetails.menuIcon"
            :iconName="configBarItem.icon"
            class="me-3 sidebar-icon"
          />
          <span
            v-if="isLabelHidden"
            :title="
              configBarItem.title.length > 16 && !configBarItem.enableFullTitle
                ? configBarItem.title
                : ''
            "
            :class="{
              'menu-title': !configBarItem.enableFullTitle,
              'menu-title full-title': configBarItem.enableFullTitle,
            }"
          >
            {{
              configBarItem.enableFullTitle
                ? configBarItem.title
                : $options.filters.truncate(configBarItem.title, 16)
            }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import { fetchFilterById } from "@/repo/filtersRepo";
import { fetchEntityById } from "@/repo/entityRepo";

export default {
  name: "SidebarDefault",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [PermissionsHelper],
  data() {
    return {
      activeDropdown: null,
      dropdownPosition: { top: 0, left: 0 },
      enableFullTitle: false,
      brandingInfo: {},
      activeLink: "62b55e9eb310d91964580ea5",
      menu: [],
      menuLoading: false,
      isOpen: "",
      routerValue: "",
      offCanvas: "",
      path: "",
      menuItems: [
        {
          title: "Company Documents",
          route: "/signup",
          icon: "company-document-2.svg",
          allowedUsers: ["GUEST"],
        },
      ],
      userMenu: [],
      defaultProps: {
        children: "children",
        title: "title",
      },
      adminMenu: true,
      userType: {},
      permissionsList: [],
      staticPaths: [
        {
          children: [],
          _id: "62b55e9eb310d91964580ea0",
          title: "Create Document",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "Create-Documents",
          route_id: "documents/upload",
          staticMenu: true,
          permission: "createNewDocument",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea5",
          title: "Dashboard",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "dashboard",
          route_id: "dashboard",
          staticMenu: true,
          permission: "dashboard",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea6",
          title: "Documents",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "Documents",
          route_id: "documents/all",
          staticMenu: true,
          permission: "documents",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea7",
          title: "Templates",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "Templates",
          route_id: "templates",
          staticMenu: true,
          permission: "documentTemplate",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea8",
          title: "Contacts",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "Contact-Active",
          route_id: "contacts",
          staticMenu: true,
          permission: "contacts",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea9",
          title: "Workflows",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "Workflows",
          route_id: "workflows",
          staticMenu: true,
          permission: "workflows",
        },
        // {
        //   children: [],
        //   _id: "62b55e9eb310d91964580eaa",
        //   title: "Request Documents",
        //   feature: "OWNERROUTES",
        //   category: "OWNERROUTES",
        //   type: "Individual",
        //   icon: "request-document_active.svg",
        //   route_id: "request-documents/select-card-type",
        //   staticMenu: true,
        //   permission: "request-documents"
        // },
        {
          children: [],
          _id: "62b55e9eb310d91964580eab",
          title: "Approval Forms",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "ApprovalForms.svg",
          route_id: "approval-forms",
          staticMenu: true,
          permission: "approval-forms",
        },
        // {
        //   children: [],
        //   _id: "62b55e9eb310d91964580ecb",
        //   title: "KF mails",
        //   feature: "OWNERROUTES",
        //   category: "OWNERROUTES",
        //   type: "Individual",
        //   icon: "ApprovalForms.svg",
        //   route_id: "integrations/mail",
        //   staticMenu: true,
        //   permission: "approval-forms",
        // }
      ],
      planDetails: {},
      isHorMenu: true,
      filtersData: [],
      filterDataArray: [],
      filtersObject: {},
      checkChild: false,
      configMenu: [],
    };
  },
  computed: {
    ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    getSideMenuSize() {
      if (!this.getIsOpen && this.getIsCollapse && !this.getIsMobile) {
        return `width:6rem !important;`;
      } else if (!this.getIsOpen && this.getIsCollapse && this.getIsMobile) {
        return `display:none !important`;
      } else if (this.getIsOpen && this.getIsCollapse && this.getIsMobile) {
        return `width:17rem !important;`;
      }
      return `width:15rem !important;`;
    },
    isLabelHidden() {
      if (!this.getIsOpen && this.getIsCollapse && !this.getIsMobile) {
        return false;
      }
      return true;
    },
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserMenu",
      "getActiveWorkspace",
      "getUserType",
      "getPermissionList",
      "getPermissions",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
    ]),
    ...mapGetters("company", [
      "getContactLoginSlug",
      "getUpdateCompany",
      "getCompanyDetails",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("customDashboard", ["getPageDashboards"]),

    ...mapGetters("UserIntegrations", ["getIntegrationsList"]),
    isCollapse: {
      get() {
        return this.getIsOpen;
      },
      set(isCollapse) {
        this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
          root: true,
        });
      },
    },
    floatingStyle() {
      return this.brandingInfo.is_floating
        ? {
            top: "8%",
            left: "300px",
            borderRadius: "10px",
            width: "13.5rem",
          }
        : { borderRight: "5px solid grey" };
    },
    getCurrentRouteName() {
      if (
        this.$router &&
        this.$router.currentRoute &&
        this.$router.currentRoute.name
      )
        return this.$router.currentRoute.name;
      else return null;
    },
    getUserMenuItems() {
      let userType = this.getActiveWorkspace ? this.userType.name : "";
      return this.menuItems.filter(
        (item) => item.allowedUsers.indexOf(userType) > -1
      );
    },
    generateObjectIdFromNumber() {
      return (number) => {
        // Generate a 24-character string based on the number
        let hexNumber = number.toString(16).padStart(24, "0");
        if (hexNumber.length > 24) {
          hexNumber = hexNumber.substring(0, 24);
        }
        // return new ObjectId(hexNumber);
        return hexNumber;
      };
    },
  },
  watch: {
    async "$route.path"() {
      if (this.$route && this.$route.path) {
        let name =
          this.$route.path.charAt(0) == "/"
            ? this.$route.path.slice(1)
            : this.$route.path;
        let ownerRoute = this.staticPaths.find((e) => e.route_id == name);
        if (ownerRoute && ownerRoute._id) {
          this.activeLink = ownerRoute._id;
        }
      }
    },

    getPaymentsHistory: {
      immediate: true,
      handler(newVal) {
        this.updatePlanDetails(newVal);
      },
    },
    $route() {
      this.checkRoute();
      if (this.getIsMobile) {
        this.isCollapse = false; // Set IsCollapse to false on route change only if getIsMobile is true
      }
    },
    getIntegrationsList: {
      immediate: true,
      async handler(newVal) {
        const filteredConfigurations = newVal.filter(
          (configuration) => configuration.isInMenu
        );
        let configMenuItems = filteredConfigurations.map(
          async (configuration, index) => {
            let menuItem = {
              children: [],
              _id: this.generateObjectIdFromNumber(index),
              feature: "OWNERROUTES",
              category: "OWNERROUTES",
              type: "Individual",
              icon: "",
              route_id: "",
              staticMenu: true,
              permission: "",
            };

            if (configuration.appName === "VITEL_GLOBAL") {
              menuItem = {
                ...menuItem,
                title: "VITEL_GLOBAL",
                icon: "ApprovalForms.svg",
                route_id: `integrations/${configuration.appName}/${configuration._id}`,
                permission: "Vitel-Global",
              };
            } else if (configuration.appName === "MAIL") {
              menuItem = {
                ...menuItem,
                title: "KF-Mail",
                icon: "MailIcon.svg", // Replace with the appropriate icon
                route_id: `integrations/${configuration.appName}/${configuration._id}`,
                permission: "KF-Mail",
              };
            }
            return menuItem;
          }
        );

        this.configMenu = await Promise.all(configMenuItems);
      },
    },
  },
  async mounted() {
    this.getMenuType();
    if (this.$route.query?.filter) {
      await this.$store.dispatch(
        "filters/getFilterById",
        this.$route.query?.filter
      );
    }
    this.getPermissionsList();
    if (
      this.getAuthenticatedUser &&
      this.getAuthenticatedUser.activeRole &&
      this.getAuthenticatedUser.activeRole.userType_id
    ) {
      // await this.$store.dispatch(
      //   "auth/getUserTypeById",
      //   this.getAuthenticatedUser.activeRole.userType_id
      // );
    }
    if (
      this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
      this.getActiveWorkspace.company_id
    ) {
      this.userType = this.getUserType;
      this.getMenuList();
    } else {
      this.menu = this.staticPaths;
    }
    if (this.$route && this.$route.path) {
      let name =
        this.$route.path.charAt(0) == "/"
          ? this.$route.path.slice(1)
          : this.$route.path;
      let ownerRoute = this.staticPaths.find((e) => e.route_id == name);
      if (ownerRoute && ownerRoute._id) {
        this.activeLink = ownerRoute._id;
      }
    }
    this.checkRoute();
    await this.getCompanyInfo();
    if (this.getIsMobile) {
      // Set isCollapse to true
      this.isCollapse = false;
    }
    let configMenuItems = this.getIntegrationsList?.map(
      async (configuration, index) => {
        let menuItem = {
          children: [],
          _id: this.generateObjectIdFromNumber(index),
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "",
          route_id: "",
          staticMenu: true,
          permission: "",
        };

        if (
          configuration.appName === "VITEL_GLOBAL" &&
          configuration.isInMenu
        ) {
          menuItem = {
            ...menuItem,
            title: "VITEL_GLOBAL",
            icon: "ApprovalForms.svg",
            route_id: `integrations/${configuration.appName}/${configuration._id}`,
            permission: "Vitel-Global",
          };
        } else if (configuration.appName === "MAIL" && configuration.isInMenu) {
          menuItem = {
            ...menuItem,
            title: "KF-Mail",
            icon: "MailIcon.svg", // Use appropriate icon
            route_id: `integrations/${configuration.appName}/${configuration._id}`,
            permission: "KF-Mail",
          };
        }

        return menuItem;
      }
    );
    this.configMenu = await Promise.all(configMenuItems);
  },
  methods: {
    closeDropdown() {
      this.activeDropdown = null; 
    },
    openDropdown(index, event) {
      if (this.activeDropdown === index) {
        this.activeDropdown = null;
      } else {
        this.activeDropdown = index;

        const rect = event.target.getBoundingClientRect();
        this.dropdownPosition = {
          top: rect.top,
          left: rect.right + 5,
        };
      }
    },
    getIconClass(route) {
      const icons = {
        dashboard: "fa fa-table",
        "documents/all": "fa fa-folder",
        templates: "fa-regular fa-folder",
        contacts: "fa fa-address-book",
        workflows: "fa fa-sitemap",
        "approval-forms": "fa fa-check",
      };
      return icons[route] || "fa fa-file";
    },
    generateRoute(route) {
      const timestamp = Date.now();

      if (route === "approval-forms") {
        return `/forms/${route}`;
      }

      return `/${route}?ts=${timestamp}`;
    },
    updatePlanDetails(getPaymentsHistory) {
      if (
        getPaymentsHistory &&
        getPaymentsHistory.planFeatures &&
        getPaymentsHistory.planFeatures.length
      ) {
        this.planDetails = getPaymentsHistory.planFeatures[0];
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        // await this.$store.dispatch(
        //   "company/fetchCompany",
        //   this.getActiveWorkspace.company_id
        // );
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    checkRoute() {
      if (
        this.$route &&
        (this.$route.name === "EntityDetailedEdit" ||
          this.$route.name === "EntityDetailedView" ||
          this.$route.name === "FormbuilderViewStep" ||
          this.$route.query.viewType === "HTML_CONTENT" ||
          this.$route.name === "AiAssistant")
      ) {
        this.isCollapse = false; // Set to true if the route matches
      } else {
        this.isCollapse = true; // Set to false otherwise
      }
    },
    expandSidebar() {
      if (
        this.$route &&
        (this.$route.name === "EntityDetailedEdit" ||
          this.$route.name === "AiAssistant" ||
          this.$route.name === "EntityDetailedView" ||
          this.$route.name === "FormbuilderViewStep" ||
          this.$route.query.viewType === "HTML_CONTENT")
      ) {
        this.isCollapse = true;
      } else {
        if (this.isCollapse) return; // Only collapse if it's currently expanded
        this.isCollapse = false;
      }
    },
    collapseSidebar() {
      if (
        this.$route &&
        (this.$route.name === "EntityDetailedEdit" ||
          this.$route.name === "EntityDetailedView" ||
          this.$route.name === "AiAssistant" ||
          this.$route.name === "FormbuilderViewStep" ||
          this.$route.query.viewType === "HTML_CONTENT")
      ) {
        this.isCollapse = false;
      } else {
        if (!this.isCollapse) return; // Only expand if it's currently collapsed
        this.isCollapse = true;
      }
    },
    async getMenuList() {
      this.menu = [];
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.activeRole &&
        this.getAuthenticatedUser.activeRole.userType_id
      ) {
        await this.$store.dispatch(
          "menuManagementV2/fetchMenuWithUserType",
          this.getAuthenticatedUser.activeRole.userType_id
        );
      }
      if (this.getMenu) {
        this.menu =
          this.getMenu && this.getMenu.menu_list
            ? this.getMenu.menu_list.map((e) => {
                if (e.entity_id && e.entity_id._id) {
                  e.entity_id = e.entity_id._id;
                }

                if (e.workflow_id && e.workflow_id._id) {
                  e.workflow_id = e.workflow_id._id;
                }
                return e;
              })
            : [];
        const checkedMenuItem = this.findCheckedMenuItem(this.menu);
        if (checkedMenuItem && this.$route.query.from_login == "true") {
          this.updatePlanDetails(this.getPaymentsHistory);
          this.routeClick(checkedMenuItem, this.checkChild);
        }
      } else if (this.userType && this.userType.name === "OWNER") {
        this.menu = [];
        this.menu = this.staticPaths;
      } else {
        this.menu = [];
        this.menu = this.staticPaths;
      }
      this.filtersData = this.getMenu
        ? (this.getMenu.menu_list || []).flatMap((e) => {
            let ids = [];
            if (e?.filters) {
              ids.push(e.filters._id);
            }
            if (e.children && e.children.length) {
              e.children.map((el) => {
                if (el?.filters) {
                  ids.push(el.filters._id);
                }
                if (el.children && el.children.length) {
                  el.children.map((ele) => {
                    if (ele?.filters) {
                      ids.push(ele.filters._id);
                    }
                  });
                }
              });
            }
            return ids;
          })
        : [];
      if (this.filtersData.length) {
        this.filterDateCall();
      }
    },
    findCheckedMenuItem(menu) {
      for (let item of menu) {
        if (item.isChecked === true) {
          this.checkChild = false;
          return item;
        }
        if (item.children && item.children.length) {
          this.checkChild = true;
          const checkedChild = this.findCheckedMenuItem(item.children);
          if (checkedChild) {
            return checkedChild;
          }
        }
      }
      return null;
    },
    async filterDateCall() {
      if (this.filtersData.length) {
        try {
          let filtersData = Array.from(new Set(this.filtersData));
          this.filterDataArray = await Promise.all(
            filtersData.map(async (id) => {
              // await this.$store.dispatch("filters/getFilterById", id);
              // return this.getSingleFilterData;
              return await fetchFilterById(id);
            })
          );

          this.filterDataArray.map((e) => {
            this.filtersObject[e._id] = e;
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
    getMenuType() {
      if (
        this.getCompanyDetails &&
        this.getCompanyDetails.menuIcon &&
        this.getCompanyDetails.menuIcon == false
      ) {
        this.isHorMenu = false;
      } else {
        this.isHormenu = true;
      }
    },
    async routeClick(data, isChild) {
      let entityData;
      if (this.planDetails.diff) {
        if (data && data.feature === "ENTITIES") {
          if (typeof data?.entity_id == "object") {
            entityData = data.entity_id;
          } else {
            entityData = await fetchEntityById(data?.entity_id, false);
          }
          this.activeLink = data._id;
          let filter;
          if (data?.filters?._id) {
            filter = this.filtersObject[data.filters._id];
          }
          const viewType =
            entityData?.viewType && data?.entity_view_type
              ? data.entity_view_type
              : entityData?.viewType
              ? entityData.viewType
              : data?.entity_view_type
              ? data.entity_view_type
              : filter?.displayType
              ? filter.displayType
              : "TABLE";
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/entity?group=${
                  data.groups
                }&routeType=subEntity&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            } else {
              if (data.redirectToAdd) {
                this.$router.push({
                  path: `/entity/edit/${
                    data.entity_id._id || entityData._id
                  }?routeType=subEntity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&customization=${
                    data.customization ? data.customization : ""
                  }&key=${Math.floor(
                    Math.random() * 100000
                  )}&addNew=true&isMenu=true&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }
                            `,
                });
              } else {
                this.$router.push({
                  path: `/entity/${
                    data.entity_id._id || entityData._id
                  }?routeType=subEntity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&customization=${
                    data.customization ? data.customization : ""
                  }&key=${Math.floor(
                    Math.random() * 100000
                  )}&isMenu=true&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }
                            &viewType=${viewType}`,
                });
              }
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/entity?group=${
                  data.groups
                }&routeType=entity&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              if (data.redirectToAdd) {
                this.$router.push({
                  path: `/entity/edit/${
                    data.entity_id
                  }?routeType=entity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&customization=${
                    data.customization ? data.customization : ""
                  }&key=${Math.floor(
                    Math.random() * 100000
                  )}&addNew=true&isMenu=true&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }
                            `,
                });
              } else {
                this.$router.push({
                  path: `/entity/${data.entity_id}?routeType=entity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&customization=${
                    data.customization ? data.customization : ""
                  }&key=${Math.floor(
                    Math.random() * 100000
                  )}&isMenu=true&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }&viewType=${viewType}`,
                });
              }
            }
          }
        } else if (data && data.feature === "FORM_BUILDER") {
          let formBuilderKey = data?.formbuilder_id?.code;
          this.activeLink = data._id;
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/formBuilders?group=${
                  data.groups
                }&routeType=subFB&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/fbd/${formBuilderKey}?routeType=subFB&key=${Math.floor(
                  Math.random() * 100000
                )}&t=${data.title}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/formBuilders?group=${
                  data.groups
                }&routeType=FB&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/fbd/${formBuilderKey}?routeType=FB&key=${Math.floor(
                  Math.random() * 100000
                )}&t=${data.title}`,
              });
            }
          }
        } else if (data && data.feature === "WORKFLOWS") {
          this.activeLink = data._id;
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/workflows?group=${
                  data.groups
                }&routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/workflow/${
                  data.workflow_id
                }?routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/workflows?group=${
                  data.groups
                }&routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/workflow/${
                  data.workflow_id
                }?routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            }
          }
        } else if (data && data.feature === "OWNERROUTES") {
          let currentRouteCheck =
            this.$route && this.$route.path && this.$route.path.substring(1);
          this.activeLink = data._id;
          if (data.route_id != currentRouteCheck) {
            if (data.route_id === "approval-forms") {
              this.$router.push({
                path: `/forms/${data.route_id}`,
              });
            } else {
              this.$router.push({
                path: `/${data.route_id}?ts=${Date.now()}`,
              });
            }
          }
        } else if (data && data.feature === "FORM_TEMPLATE") {
          this.activeLink = data._id;

          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/template?group=${
                  data.groups
                }&routeType=subFT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/template?routeType=subFT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/template?group=${
                  data.groups
                }&routeType=FT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/template?routeType=FT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          }
        } else if (data && data.feature === "DOC_TEMPLATE") {
          this.activeLink = data._id;

          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/templates?group=${
                  data.groups
                }&routeType=subDT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/templates?routeType=subDT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/templates?group=${
                  data.groups
                }&routeType=DT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/templates?routeType=DT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          }
        } else if (data && data.feature === "ENTITY_VIEWS") {
          this.$router.push({
            path: `/entity-views/view/${
              data.entityviews_id
            }?routeType=entityviews&key=${Math.floor(Math.random() * 100000)}`,
          });
        } else if (data && data.feature === "PAGE_DASHBOARD") {
          this.$store.commit("customDashboard/setPageDashboards", null, {
            root: true,
          });
          let pageDashboardIds = this.getPageDashboardLink(
            data?.page_dashboard
          );
          if (pageDashboardIds?.length == 1) {
            let dashboardId = pageDashboardIds?.[0]?.split("#")?.[0];
            this.$router.push({
              path: `/page-dashboard/${dashboardId}?key=${Math.floor(
                Math.random() * 100000
              )}&menu_id=${data._id}`,
            });
          } else {
            let dashData = pageDashboardIds.map((item) => {
              let [id, name] = item.split("#");
              return { id, name };
            });
            this.$store.commit("customDashboard/setPageDashboards", dashData, {
              root: true,
            });
            let dashboardId = pageDashboardIds?.[0]?.split("#")?.[0];
            this.$router.push({
              path: `/page-dashboard/${dashboardId}?key=${Math.floor(
                Math.random() * 100000
              )}&menu_id=${data._id}`,
            });
          }
        } else if (data && data.feature === "A_FORM_TEMPLATE") {
          const routePath = `/template/${data.template_id}/menu/view`;
          if (this.$route.path !== routePath) {
            this.$router.push(routePath).catch(() => {});
          }
        } else if (data && data.feature === "EXTERNAL_LINK") {
          this.$router.push(`/externalLink/${data._id}`);
        } else {
          this.activeLink = "62b55e9eb310d91964580ea5";
          this.$router.push({
            path: `/dashboard`,
          });
        }
        this.$store.commit("navigationOpen/setCurrentMenu", data, {
          root: true,
        });
      } else {
        this.$message({
          message: "Your package has expired.",
          type: "error",
        });
        // this.$router.push("/expire")
      }
      this.activeDropdown = null;
    },
    getPageDashboardLink(data) {
      if (typeof data == "string") {
        return [data];
      }
      return data;
    },
    async getPermissionsList() {
      try {
        this.loading = true;
        // await this.$store.dispatch("auth/getPermissions");
        if (this.getPermissionList) {
          this.permissionsList = this.getPermissionList;
        }
        this.loading = false;
      } catch (err) {
        console.log("getPermissionsList", err);
      }
    },
    checkPermissionsStatus(menuData) {
      if (
        this.isIndividualWorkspace(this.getActiveWorkspace) &&
        menuData &&
        (menuData.permission === "companyUsers" ||
          menuData.permission === "workflows" ||
          menuData.permission === "approval-forms")
      ) {
        return false;
      } else if (menuData && menuData.feature === "OWNERROUTES") {
        if (this.checkPermissionsCategory(menuData.permission)) {
          return true;
        } else if (this.checkPermissionByPermissionName(menuData.permission)) {
          return true;
        } else if (menuData.permission === "dashboard") {
          return true;
        } else {
          {
            return false;
          }
        }
      } else if (menuData && menuData.feature === "FORM_BUILDER") {
        // Check form builder permission permission
        return this.checkPermissionsCategory("formBuilder");
      } else if (menuData && menuData.feature === "ENTITIES") {
        // Check Entities permission
        return this.checkPermissionsCategory("entities");
      } else if (menuData && menuData.feature === "WORKFLOWS") {
        // Check workflow permission permission
        return this.checkPermissionsCategory("workflows");
        // return true;
      } else if (menuData && menuData.feature === "DOC_TEMPLATE") {
        // Check workflow permission permission
        // return this.checkPermissionsCategory("workflows");
        return true;
      } else if (menuData && menuData.feature === "FORM_TEMPLATE") {
        // Check workflow permission permission
        // return this.checkPermissionsCategory("workflows");
        return true;
      } else if (menuData && menuData.feature === "ENTITY_VIEWS") {
        // Entity view permissions need to be added
        return true;
      } else if (menuData && menuData.feature === "PAGE_DASHBOARD") {
        return true;
      } else if (menuData && menuData.feature === "A_FORM_TEMPLATE") {
        return true;
      } else if (menuData && menuData.feature === "EXTERNAL_LINK") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 15rem;
  background-color: var(--sidemenu-background-color);
  color: var(--sidemenu-text-color) !important;
  border-right: 1px solid #e0e0e0;
  padding-top: 20px;
  position: absolute;
  top: 8%;
  max-height: 90vh;
  overflow-y: auto;
  height: 90vh;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  left: 5px;
  border-radius: 10px;
}

.nav-link {
  color: var(--sidemenu-text-color);
  padding: 12px 16px;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.nav-link .sidebar-icon {
  fill: var(--icon-color);
  transition: color 0.2s ease-in-out;
}

.nav-link:hover {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
}

.nav-link:hover .sidebar-icon {
  fill: var(--icon-hover-color);
  transition: color 0.2s ease-in-out;
}

.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  position: sticky;
  width: 13rem;
}

.dropdown-item {
  display: flex;
  color: #333;
  padding: 10px 16px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  white-space: normal;
}

.dropdown-item:hover {
  background-color: var(--primary-contrast-color);
  color: var(--primary-color);
}

.dropdown-item:hover .sidebar-icon {
  background-color: var(--primary-contrast-color);
  color: var(--icon-hover-color);
  transition: color 0.2s ease-in-out;
}

.dropdown-arrow {
  color: var(--primary-contrast-color);
  transition: transform 0.2s;
}

.dropend .dropdown-arrow {
  transform: rotate(0deg);
}

.me-3 {
  margin-right: 12px;
}

.nav-link.active {
  background-color: #e9e9f6;
  color: #8a2be2;
}

.nav-link.active .sidebar-icon {
  color: #8a2be2;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
  }
}
</style>
