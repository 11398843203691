var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sidebar",staticClass:"d-flex flex-column flex-shrink-0 p-3 sidebar",style:(_vm.getSideMenuSize),on:{"mouseleave":_vm.closeDropdown}},[_c('ul',{staticClass:"nav nav-pills flex-column mb-auto"},[_vm._l((_vm.menu),function(sideBarItem,i){return _c('li',{key:sideBarItem._id,staticClass:"nav-item"},[(sideBarItem.children && sideBarItem.children.length)?_c('div',{staticClass:"dropend position-relative"},[_c('div',{staticClass:"nav-link d-flex justify-content-between align-items-center",on:{"click":function($event){return _vm.openDropdown(i, $event)}}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{staticClass:"me-3 sidebar-icon",attrs:{"iconName":sideBarItem.icon}}):_vm._e(),(_vm.isLabelHidden)?_c('span',[_vm._v(" "+_vm._s(sideBarItem.enableFullTitle ? sideBarItem.title : _vm.$options.filters.truncate(sideBarItem.title, 15))+" ")]):_vm._e()],1),_c('span',{staticClass:"dropdown-arrow"},[_vm._v("▶")])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeDropdown === i),expression:"activeDropdown === i"}],ref:"dropdown",refInFor:true,staticClass:"dropdown-menu show",style:({
            top: '5' + '%',
            left: '110' + '%',
          })},_vm._l((sideBarItem.children),function(sideBarSubItem){return _c('li',{key:sideBarSubItem._id,on:{"click":function($event){return _vm.routeClick(sideBarSubItem, true)}}},[_c('div',{staticClass:"dropdown-item"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{staticClass:"me-3 sidebar-icon",attrs:{"iconName":sideBarSubItem.icon}}):_vm._e(),(_vm.isLabelHidden)?_c('span',[_vm._v(" "+_vm._s(sideBarSubItem.title)+" ")]):_vm._e()],1)])}),0)]):[_c('div',{staticClass:"nav-link d-flex align-items-center",on:{"click":function($event){return _vm.routeClick(sideBarItem, true)}}},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{staticClass:"me-3 sidebar-icon",attrs:{"iconName":sideBarItem.icon}}):_vm._e(),(_vm.isLabelHidden)?_c('span',[_vm._v(" "+_vm._s(sideBarItem.title)+" ")]):_vm._e()],1)]],2)}),_vm._l((_vm.configMenu),function(configBarItem,i){return _c('li',{key:i + configBarItem._id,staticClass:"nav-item",class:{
        'dynamic-height': configBarItem.enableFullTitle,
        'fixed-height': !configBarItem.enableFullTitle,
      },on:{"click":function($event){return _vm.routeClick(configBarItem, false)}}},[_c('div',{staticClass:"nav-link d-flex align-items-center"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{staticClass:"me-3 sidebar-icon",attrs:{"iconName":configBarItem.icon}}):_vm._e(),(_vm.isLabelHidden)?_c('span',{class:{
            'menu-title': !configBarItem.enableFullTitle,
            'menu-title full-title': configBarItem.enableFullTitle,
          },attrs:{"title":configBarItem.title.length > 16 && !configBarItem.enableFullTitle
              ? configBarItem.title
              : ''}},[_vm._v(" "+_vm._s(configBarItem.enableFullTitle ? configBarItem.title : _vm.$options.filters.truncate(configBarItem.title, 16))+" ")]):_vm._e()],1)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }